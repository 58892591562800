const spotify = document.querySelector(".h-floating-socials > .h-socials-link--spotify")
const facebook = document.querySelector(".h-floating-socials > .h-socials-link--facebook")
const instagram = document.querySelector(".h-floating-socials > .h-socials-link--instagram")

window.addEventListener("scroll", () => {
  if (window.outerWidth > 992) {
    // Instagram icon
    if (window.scrollY > 120) {
      instagram.classList.add("h-socials-link--instagram-yellow")
    }

    if (window.scrollY < 120) {
      instagram.classList.remove("h-socials-link--instagram-yellow")
    }

    // Facebook icon
    if (window.scrollY > 200) {
      facebook.classList.add("h-socials-link--facebook-yellow")
    }

    if (window.scrollY < 200) {
      facebook.classList.remove("h-socials-link--facebook-yellow")
    }

    // Spotify icon
    if (window.scrollY > 280) {
      spotify.classList.add("h-socials-link--spotify-yellow")
    }

    if (window.scrollY < 280) {
      spotify.classList.remove("h-socials-link--spotify-yellow")
    }
  } else {
    // Instagram icon
    if (window.scrollY > 30) {
      instagram.classList.add("h-socials-link--instagram-yellow")
    }

    if (window.scrollY < 30) {
      instagram.classList.remove("h-socials-link--instagram-yellow")
    }

    // Facebook icon
    if (window.scrollY > 100) {
      facebook.classList.add("h-socials-link--facebook-yellow")
    }

    if (window.scrollY < 100) {
      facebook.classList.remove("h-socials-link--facebook-yellow")
    }

    // Spotify icon
    if (window.scrollY > 170) {
      spotify.classList.add("h-socials-link--spotify-yellow")
    }

    if (window.scrollY < 170) {
      spotify.classList.remove("h-socials-link--spotify-yellow")
    }
  }
})