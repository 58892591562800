import '../assets/images/icons/logo.png';
import '../assets/images/icons/spotify.png';
import '../assets/images/icons/instagram.png';
import '../assets/images/icons/facebook.png';
import '../assets/images/1.jpg';
import '../assets/images/2.jpg';
import '../assets/images/3.jpg';
import '../assets/images/4.jpg';
import '../assets/images/5.jpg';
import '../assets/images/6.jpg';
import '../assets/images/8.jpg';
import '../assets/images/9.png';
import '../assets/images/og-image.jpg';