import Splide from '@splidejs/splide';
import { TRUE } from 'sass';

new Splide('.splide', {
  type: 'loop',
  perPage: 1,
  width: '100vw',
  height: '90vh',
  pagination: false,
  arrows: false,
  easing: 'ease-in',
  drag: true,
  snap: true,
  autoplay: true,
  interval: 3000,
  pauseOnHover: false
}).mount();